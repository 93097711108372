<template>
  <v-row
    class="mb-5"
  >
    <v-col
      sm="6"
      md="3"
      cols="12"
    >
      <v-card>
        <v-card-text class="d-flex align-center justify-space-between pa-4">
          <div>
            <h2 class="font-weight-semibold mb-1">
              {{ meetings }}
            </h2>
            <span>Rendez-vous</span>
          </div>
          <v-avatar>
            <v-icon
              color="primary"
            >
            </v-icon>
          </v-avatar>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      sm="6"
      md="3"
      cols="12"
    >
      <v-card>
        <v-card-text class="d-flex align-center justify-space-between pa-4">
          <div>
            <h2 class="font-weight-semibold mb-1">
              {{ tuteurs }}
            </h2>
            <span>Tuteurs</span>
          </div>
          <v-avatar>
            <v-icon
              color="info"
            >
            </v-icon>
          </v-avatar>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      sm="6"
      md="3"
      cols="12"
    >
      <v-card>
        <v-card-text class="d-flex align-center justify-space-between pa-4">
          <div>
            <h2 class="font-weight-semibold mb-1">
              {{ meetingsToday }}
            </h2>
            <span>visio ce jour</span>
          </div>
          <v-avatar>
            <v-icon
              color="primary"
            >
            </v-icon>
          </v-avatar>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      sm="6"
      md="3"
      cols="12"
    >
      <v-card>
        <v-card-text class="d-flex align-center justify-space-between pa-4">
          Coming soon
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    meetings: {
      type: Number,
      default: 0,
    },
    tuteurs: {
      type: Number,
      default: 0,
    },
    meetingsToday: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style>

</style>
